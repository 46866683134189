import React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Typography,
  Grid,
  Button,
  Modal,
  Box,
  TextField,
  Autocomplete,
  Divider,
  IconButton,
  FormControlLabel,
  Switch,
  DialogContent,
  DialogTitle,
  Dialog,
  DialogActions,
  DialogContentText,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
  FormHelperText,
} from "@mui/material";
import i18n from "../../i18n";
import ConsumptionsTable from "./ConsumptionsTable";
import DragNDropContainer from "./DragAndDropContainer";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { DesktopTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import styled from "styled-components";
import EditIcon from "@mui/icons-material/Edit";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import config from "../../config";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import AddIcon from "@mui/icons-material/Add";
import PrinterTable from "../../components/Shared/PrinterTable";
import { deepClone } from "../../utils/deepClone";

const CustomTimePicker = styled(DesktopTimePicker)`
  width: 100%;
  margin: 20px 0;
`;
const StatusSwitch = styled(Switch)(({ theme }) => ({
  padding: 8,
  "& .MuiSwitch-track": {
    borderRadius: 22 / 2,
    "&:before, &:after": {
      content: '""',
      position: "absolute",
      top: "50%",
      transform: "translateY(-50%)",
      width: 16,
      height: 16,
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "none",
    width: 16,
    height: 16,
    margin: 2,
  },
}));
class AccordionGroup extends React.Component {
  constructor(props) {
    super(props);
    this.getItemVariable = {};
  this.orderStatusOptions = config.orderStatusOptions;
    this.Swal = require("sweetalert2");
    this.state = {
      items: {},
      name: "",
      defaultOrderStatus: "",
      availableFrom: "",
      availableTo: "",
      dropPosition: "",
      modal: {
        showGroupModal: false,
        showCategoryModal: false,
        showConsumptionsModal: false,
      },
      deletePopUp: {
        group: false,
        category: false,
        consumption: false,
      },
      formErrors: {
        groupError: {},
        categoryError: {},
        consumptionError: {},
      },
      editGroup: false,
      editCategory: false,
    };
    this.style = {
      modelClass: {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "50%",
        bgcolor: "background.paper",
        border: "2px solid #000",
        boxShadow: 24,
        p: 4,
      },
      dividerStyles: {
        marginBottom: "10px",
        background: "black",
      },
      highlight: {
        border: "1px solid #0085f2",
        padding: "0px 10px 0px 10px",
        borderRadius: "10px",
      },
    };
    this.handleConsumption = this.handleConsumption.bind(this);
    this.onDropEventHandler = this.onDropEventHandler.bind(this);
    this.highlightDrop = this.highlightDrop.bind(this);
    this.updatePrinterData = this.updatePrinterData.bind(this);
  }

  async componentDidMount() {
    if (this.props?.data) {
      let data = { children: [...this?.props?.data] };
      this.setState({
        items: { ...data },
      });
    }
  }

  getBlock(itemsData, path) {
    for (let i = 0; i < itemsData?.length; i += 1) {
      if (itemsData[i]?.path === path) {
        this.getItemVariable = itemsData[i];
      } else if (path?.indexOf(itemsData[i]?.path) === 0) {
        this.getBlock(itemsData[i]?.children, path);
      }
    }
    return this.getItemVariable;
  }
  updatePath(data, path) {
    let temp = data?.map((el, i) => {
      const tempBlockData = JSON.parse(JSON.stringify(el));
      if (path) {
        tempBlockData.path = `${path}.${i}`;
      } else {
        tempBlockData.path = String(i);
      }
      if (el?.children?.length > 0) {
        tempBlockData.children = this.updatePath(
          tempBlockData?.children,
          tempBlockData.path
        );
      }
      return tempBlockData;
    });
    return temp;
  }
  async highlightDrop(path, position) {
    this.setState({
      dropPosition: path,
    });
  }
  async onDropEventHandler(draggedItemData, destPath) {
    this.setState({
      dropPosition: "",
    });
    if (destPath !== "") {
      const tempData = JSON.parse(JSON.stringify(this.state.items));
      const splitPath = destPath?.split(".");
      let tempSrcIndex = draggedItemData?.dragItem?.element?.path.split(".");
      let tempDestinationIndex = destPath.split(".");
      const sourceParentPath = tempSrcIndex
        .splice(0, tempSrcIndex.length - 1)
        .join(".");
      const parentPath = tempDestinationIndex
        .splice(0, tempDestinationIndex.length - 1)
        .join(".");
      const sourceIndex = Number(tempSrcIndex?.[tempSrcIndex.length - 1]);
      const destIndex = Number(
        tempDestinationIndex?.[tempDestinationIndex.length - 1]
      );
      if (splitPath?.length > 1) {
        // multi-level hierarchy
        const sourceParent = this.getBlock(
          tempData?.children,
          sourceParentPath
        );
        const destParent = this.getBlock(tempData?.children, parentPath);
        sourceParent?.children?.splice(sourceIndex, 1);
        destParent?.children?.splice(
          destIndex,
          0,
          JSON.parse(JSON.stringify(draggedItemData?.dragItem?.element))
        );
      } else {
        tempData?.children?.splice(
          Number(draggedItemData?.dragItem?.element?.path),
          1
        );

        tempData?.children?.splice(
          Number(destPath),
          0,
          JSON.parse(JSON.stringify(draggedItemData?.dragItem?.element))
        );
      }
      const tempItemsData = this.updatePath(tempData?.children);
      let items = this.state.items || {};
      items['children'] = tempItemsData;
      this.setState({
              items
      });
      this.props.getItemsData(tempItemsData);
    }
  }
  handleAddEditDeleteGroup(action, deleteIndex) {
    let temp = [];
    if (action === "delete") {
      let group = this.state.group || {};
      group['deleteIndex'] = deleteIndex;
      let deletePopUp = this.state?.deletePopUp || {};
      deletePopUp['group'] = true;
      this.setState({
            group,
            deletePopUp
      });
      return;
    } else if (action === "confirmDelete") {
      temp = deepClone(this.state.items?.children);
      temp?.splice(this.state?.group?.deleteIndex, 1);
      delete this.state?.group?.deleteIndex;
      let deletePopUp = this.state?.deletePopUp || {};
      deletePopUp['group'] = false;
      this.setState({
          deletePopUp
      });
      this.Swal.fire({
        toast: true,
        icon: "success",
        position: "top-end",
        text: i18n.t("group_deleted_successfully"),
        showConfirmButton: false,
        timer: 3000,
      });
    } else if (this.state.editGroup) {
      let validateName = false;
      this.state.items?.children?.map((el) => {
        if (
          el?.name.toLowerCase().trim() ===
            this.state?.group?.name.toLowerCase().trim() &&
          el?._id !== this.state?.group?._id
        ) {
          let error = deepClone(this.state.formErrors);
          error["groupError"]["name"] = i18n.t(
            "this_group_name_already_exists"
          );
          this.setState({ formErrors: error });
          validateName = true;
        }
      });
      if (validateName) {
        return;
      }
      let editIndex = this.state?.group?.editIndex;
      delete this.state?.group?.editIndex;
      temp = deepClone(this.state.items?.children);
      temp[editIndex] = this.state?.group;
      this.props.showDiscardWarning(true);
    } else {
      temp = deepClone(this.state.items?.children);
      let validateName = false;
      this.state.items?.children?.map((el) => {
        if (
          el?.name.toLowerCase().trim() ===
            this.state?.group?.name.toLowerCase().trim() &&
          el?._id !== this.state?.group?._id
        ) {
          let error = deepClone(this.state.formErrors);
          error["groupError"]["name"] = i18n.t(
            "this_group_name_already_exists"
          );
          this.setState({ formErrors: error });
          validateName = true;
        }
      });
      if (validateName) {
        return;
      }
      let groupData = deepClone(this.state?.group)
      groupData['defaultOrderStatus'] = this.state?.group?.defaultOrderStatus;
      groupData['children'] = [];
      
      temp.push(groupData);
      this.props.showDiscardWarning(true);
    }

    const tempItemsData = this.updatePath(temp);
    this.props.getItemsData(tempItemsData);
    let items = deepClone(this.state?.items)
    items['children'] = deepClone(tempItemsData)
    let modal = deepClone(this.state?.modal)
    modal['showGroupModal'] = false;
    this.setState({
      items,
      modal
    });
  }
  handleAddEditDeleteCategory(action, groupIndex, deleteIndex) {
    let tempData;
    if (action === "delete") {
      let categoryData = deepClone(this.state?.categoryData || {});
      categoryData['deleteIndex'] = deleteIndex
      categoryData['groupIndex'] = groupIndex
      let deletePopUp = deepClone(this.state?.deletePopUp)
      deletePopUp['category'] = true;
      this.setState({
          categoryData,
          deletePopUp
      });
      return;
    } else if (action === "confirmDelete") {
      tempData = deepClone(this.state.items);
      tempData.children[
        this.state?.categoryData?.groupIndex
      ].children.splice(this.state?.categoryData?.deleteIndex, 1);
      delete this.state?.group?.deleteIndex;
      delete this.state?.group?.groupIndex;
      let deletePopUp = deepClone(this.state?.deletePopUp)
      deletePopUp['category'] = false;
      this.setState({
        deletePopUp,
      });
      this.Swal.fire({
        toast: true,
        icon: "success",
        position: "top-end",
        text: i18n.t("category_deleted_successfully"),
        showConfirmButton: false,
        timer: 3000,
      });
    } else if (this.state.editCategory) {
      tempData = deepClone(this.state.items);
      let validateName = false;
      tempData.children[
        this.state?.categoryData?.groupIndex
      ].children?.map((el) => {
        if (
          el?.name.toLowerCase().trim() ===
            this.state?.categoryData?.name.toLowerCase().trim() &&
          el?._id !== this.state?.categoryData?._id
        ) {
          let error = deepClone(this.state.formErrors);
          error["categoryError"]["name"] = i18n.t(
            "this_category_name_already_exists"
          );
          this.setState({ formErrors: error });
          validateName = true;
        }
      });
      if (validateName) {
        return;
      }

      let editIndex = this.state?.categoryData?.editIndex;
      delete this.state?.categoryData?.editIndex;
      let groupIndex = this.state?.categoryData?.groupIndex;
      delete this.state?.categoryData?.groupIndex;
      tempData.children[groupIndex].children[editIndex] =
        this.state?.categoryData;
      this.props.showDiscardWarning(true);
    } else {
      tempData = deepClone(this.state.items);
      let validateName = false;
      tempData.children[
        this.state?.categoryData?.groupIndex
      ].children?.map((el) => {
        if (
          el?.name.toLowerCase().trim() ===
            this.state?.categoryData?.name.toLowerCase().trim() &&
          el?._id !== this.state?.categoryData?._id
        ) {
          let error = deepClone(this.state.formErrors);
          error["categoryError"]["name"] = i18n.t(
            "this_category_name_already_exists"
          );
          this.setState({ formErrors: error });
          validateName = true;
        }
      });
      if (validateName) {
        return;
      }
      let index = this.state?.categoryData?.groupIndex;
      delete this.state?.categoryData?.groupIndex;
      let categoryData = deepClone(this.state?.categoryData);
      categoryData['children'] = [];
      tempData?.children[index]?.children.push(categoryData);
      this.props.showDiscardWarning(true);
    }

    const tempItemsData = this.updatePath(tempData?.children);
    this.props.getItemsData(tempItemsData);
    let items = deepClone(this.state.items);
    items['children'] = deepClone(tempItemsData);
    let modal = deepClone(this.state?.modal);
    modal['showCategoryModal'] = false;
    this.setState({
      items,
      modal,
    });
  }
  async handleConsumption(action, categoryIndex, groupIndex, editDeleteIndex, data) {
    if (action === "addConsumption") {
      let tempData = deepClone(this.state.items);
      let validateName = false;
      let groupIndex = this.state?.consumptionData?.groupIndex;
      let categoryIndex = this.state?.consumptionData?.categoryIndex;
      tempData?.children[groupIndex]?.children[categoryIndex]?.children?.map(
        (el) => {
          if (
            el?.name.toLowerCase().trim() ===
              this.state?.consumptionData?.name.toLowerCase().trim() &&
            el?._id !== this.state?.consumptionData?._id
          ) {
            let error = deepClone(this.state.formErrors);
            error["consumptionError"]["name"] = i18n.t(
              "this_consumption_name_already_exists"
            );
            this.setState({ formErrors: error });
            validateName = true;
          }
        }
      );
      if (validateName) {
        return;
      }

      delete this.state?.consumptionData?.groupIndex;
      delete this.state?.consumptionData?.categoryIndex;
      let consumptionData = deepClone(this.state?.consumptionData);
      consumptionData['children'] = [];
      consumptionData['type'] = this.state?.consumptionData?.type?.value;
      consumptionData['_id'] = `${
          Math.random() * 100 + 10 * Math.random() * 10
        }${groupIndex}${categoryIndex}`;
      tempData?.children[groupIndex]?.children[categoryIndex]?.children.push(consumptionData);
      const tempItemsData = this.updatePath(tempData?.children);
      this.props.getItemsData(tempItemsData);
      let items = deepClone(this.state.items);
      items['children'] = deepClone(tempItemsData);
      let modal = deepClone(this.state.modal);
      modal['showConsumptionsModal'] = false;
      this.setState({
        items,
        modal,
      });
      this.props.showDiscardWarning(true);
    } else if (action === "updateIndex") {
      let modal = deepClone(this.state.modal);
      modal['showConsumptionsModal'] = true;
      let formErrors = deepClone(this.state?.formErrors);
      formErrors['consumptionError'] = {};
      this.setState({
          modal,
          consumptionData: {
            categoryIndex: categoryIndex,
            groupIndex,
            quickOrder: false,
          },
          formErrors,
      });
    } else if (action === "showEditConsumptionModal") {
      let consumptionType = config?.consumptionRoundingTypes?.filter(types=>types?.value === data?.type)?.[0]
      let consumptionData = deepClone(data);
      consumptionData['type'] = consumptionType;
      consumptionData['editIndex'] = editDeleteIndex;
      consumptionData['categoryIndex'] = categoryIndex;
      consumptionData['groupIndex'] = groupIndex;
      let modal = deepClone(this.state?.modal);
      modal['showConsumptionsModal'] = true;
      let formErrors = deepClone(this.state?.formErrors);
      formErrors['consumptionError'] = {};
      this.setState({
          consumptionData,
          formErrors,
          modal,
      });
    } else if (action === "editConsumption") {
      let tempData = deepClone(this.state.items);
      let validateName = false;
      let groupIndex = this.state?.consumptionData?.groupIndex;
      let categoryIndex = this.state?.consumptionData?.categoryIndex;
      tempData?.children[groupIndex]?.children[categoryIndex]?.children?.map(
        (el) => {
          if (
            el?.name.toLowerCase().trim() ===
              this.state?.consumptionData?.name.toLowerCase().trim() &&
            el?._id !== this.state?.consumptionData?._id
          ) {
            let error = deepClone(this.state.formErrors);
            error["consumptionError"]["name"] = i18n.t(
              "this_consumption_name_already_exists"
            );
            this.setState({ formErrors: error });
            validateName = true;
          }
        }
      );
      if (validateName) {
        return;
      }

      let editIndex = this.state?.consumptionData?.editIndex;
      delete this.state?.consumptionData?.editIndex;
      delete this.state?.consumptionData?.groupIndex;
      delete this.state?.consumptionData?.categoryIndex;
      let consumptionData = deepClone(this.state?.consumptionData);
      consumptionData['type'] = this.state?.consumptionData?.type?.value;
      await this.setState({
          consumptionData
      })
      tempData.children[groupIndex].children[categoryIndex].children[
        editIndex
      ] = this.state?.consumptionData;
      const tempItemsData = this.updatePath(tempData?.children);
      this.props.getItemsData(tempItemsData);

      let items = deepClone(this.state.items);
      items['children'] = deepClone(tempItemsData)
      let modal = deepClone(this.state.modal);
      modal['showConsumptionsModal'] = false;
      this.setState({
        items,
        modal
      });
      this.props.showDiscardWarning(true);
    } else if (action === "delete") {
      let consumptionData = deepClone(this.state?.consumptionData || {}) ;
      consumptionData['groupIndex']=groupIndex;
      consumptionData['categoryIndex']=categoryIndex;
      consumptionData['editDeleteIndex']=editDeleteIndex;
      let deletePopUp = deepClone(this.state?.deletePopUp);
      deletePopUp['consumption'] = true;
      this.setState({
          consumptionData,
          deletePopUp,
      });
      return;
    } else if (action === "confirmDelete") {
      let tempData = deepClone(this.state.items);
      tempData?.children[
        this.state?.consumptionData?.groupIndex
      ]?.children[
        this.state?.consumptionData?.categoryIndex
      ]?.children.splice(
        this.state?.consumptionData?.editDeleteIndex,
        1
      );
      const tempItemsData = this.updatePath(tempData?.children);
      this.props.getItemsData(tempItemsData);
      let items = deepClone(this.state.items);
      items['children'] = deepClone(tempItemsData);
      let deletePopUp = deepClone(this.state?.deletePopUp);
      deletePopUp['consumption'] = false;
      this.setState({
        items,
        deletePopUp,
      });
      this.Swal.fire({
        toast: true,
        icon: "success",
        position: "top-end",
        text: i18n.t("consumption_deleted_successfully"),
        showConfirmButton: false,
        timer: 3000,
      });
      delete this.state?.consumptionData?.groupIndex;
      delete this.state?.consumptionData?.categoryIndex;
      delete this.state?.consumptionData?.editDeleteIndex;
    }
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps?.data) {
      let data = { children: [...nextProps?.data] };
      this.setState({
        items: { ...data },
      });
    }
  }

  async isFormValid(key) {
    let error;
    if (key === "groupError") {
      error = await this.validateFormField();
    } else if (key === "categoryError") {
      error = await this.validateCategoryFormField();
    } else if (key === "consumptionError") {
      error = await this.validateConsumptionFormField();
    }
    if (Object?.keys(error[key])?.length > 0) {
      return false;
    } else {
      return true;
    }
  }
  async validateFormField(field) {
    let error = deepClone(this.state.formErrors);
    if (field == null || field === "name") {
      if (
        !this.state?.group?.name ||
        this.state?.group?.name === ""
      ) {
        error["groupError"]["name"] = i18n.t("this_field_is_required");
      } else {
        delete error?.["groupError"]["name"];
      }
    }

    if (field == null || field === "defaultOrderStatus") {
      if (
        !this.state?.group?.defaultOrderStatus ||
        this.state?.group?.defaultOrderStatus === ""
      ) {
        error["groupError"]["defaultOrderStatus"] = i18n.t(
          "this_field_is_required"
        );
      } else {
        delete error?.["groupError"]["defaultOrderStatus"];
      }
    }

    this.setState({ formErrors: error });

    return error;
  }
  async validateCategoryFormField(field) {
    let error = deepClone(this.state.formErrors);
    if (field == null || field === "name") {
      if (
        !this.state?.categoryData?.name ||
        this.state?.categoryData?.name === ""
      ) {
        error["categoryError"]["name"] = i18n.t("this_field_is_required");
      } else {
        delete error?.["categoryError"]["name"];
      }
    }

    this.setState({ formErrors: error });

    return error;
  }

  async validateConsumptionFormField(field) {
    let error = deepClone(this.state.formErrors);
    if (field == null || field === "name") {
      if (
        !this.state?.consumptionData?.name ||
        this.state?.consumptionData?.name === ""
      ) {
        error["consumptionError"]["name"] = i18n.t("this_field_is_required");
      } else {
        delete error?.["consumptionError"]["name"];
      }
    }
    if (field == null || field === "price") {
      if (
        !this.state?.consumptionData?.price ||
        this.state?.consumptionData?.price === ""
      ) {
        error["consumptionError"]["price"] = i18n.t("this_field_is_required");
      } else {
        delete error?.["consumptionError"]["price"];
      }
    }
    if (field == null || field === "type") {
      if (
        !this.state?.consumptionData?.type?.value ||
        this.state?.consumptionData?.type?.value === ""
      ) {
        error["consumptionError"]["type"] = i18n.t("this_field_is_required");
      } else {
        delete error?.["consumptionError"]["type"];
      }
    }
    if (this.state?.consumptionData?.type?.value === 'input' && (field == null || field === "round")) {
      if (
        !this.state?.consumptionData?.round ||
        this.state?.consumptionData?.round === ""
      ) {
        error["consumptionError"]["round"] = i18n.t("this_field_is_required");
      } else {
        delete error?.["consumptionError"]["round"];
      }
    }

    // if (field == null || field === "description") {
    //   if (
    //     !this.state?.consumptionData?.description ||
    //     this.state?.consumptionData?.description === ""
    //   ) {
    //     error["consumptionError"]["description"] = i18n.t("this_field_is_required");
    //   } else {
    //     delete  error?.["consumptionError"]["description"];
    //   }
    // }
    //   if (field == null || field === "vat_rates") {
    //     if (
    //       !String(this.state?.consumptionData?.VAT) ||
    //       this.state?.consumptionData?.VAT === ""
    //     ) {
    //       error["consumptionError"]["vat_rates"] = i18n.t("this_field_is_required");
    //     } else {
    //       delete  error?.["consumptionError"]["vat_rates"];
    //     }
    // }

    this.setState({ formErrors: error });

    return error;
  }
  async saveGroupData() {
    if (!(await this.isFormValid("groupError"))) {
      return;
    }
    this.handleAddEditDeleteGroup();
  }

  async saveCategoryData() {
    if (!(await this.isFormValid("categoryError"))) {
      return;
    }
    this.handleAddEditDeleteCategory();
  }

  async saveConsumptionData() {
    if (!(await this.isFormValid("consumptionError"))) {
      return;
    }
    if (this.state?.consumptionData?._id) {
      this.handleConsumption("editConsumption");
    } else {
      this.handleConsumption("addConsumption");
    }
  }

  handleConfirmDialog() {
    if (this.state?.deletePopUp?.group) {
      this.handleAddEditDeleteGroup("confirmDelete");
    } else if (this.state?.deletePopUp?.category) {
      this.handleAddEditDeleteCategory("confirmDelete");
    } else if (this.state?.deletePopUp?.consumption) {
      this.handleConsumption("confirmDelete");
    }
  }
  async updatePrinterData(printerData, groupIndex) {
    let tempGroupData = this.state.items;
    tempGroupData["children"][groupIndex]["printers"] = printerData;
    this.props.getItemsData(tempGroupData?.children);
  }
  render() {
    return (
      <>
        <DndProvider backend={HTML5Backend}>
          <Grid
            container
            spacing={2}
            className="mb-2"
            style={{ display: "flex", alignItems: "center" }}
          >
            <Grid item xs={8} style={{ textAlign: "start", margin: "0" }}>
              <Typography variant="h5">{i18n.t("groups")}</Typography>
            </Grid>
            <Grid item xs={4} style={{ textAlign: "end" }}>
              <Button
                variant="outlined"
                startIcon={<AddIcon />}
                onClick={() => {
                  let modal = this.state.modal || {};
                  modal['showGroupModal'] = true;
                  this.setState({
                    group: {
                      
                    },
                    modal,
                    editGroup: false,
                  });
                  this.props.showDiscardWarning(true);
                }}
              >
                {i18n.t("add_group")}
              </Button>
            </Grid>
          </Grid>
          <DragNDropContainer
            key="empty-div"
            onDrop={this.onDropEventHandler}
            highlightDrop={this.highlightDrop}
            path="0"
            containerType="droppable"
            isEmpty
            canDrag={true}
            dragType=""
            dropType={["groups"]}
            padding="5px 0"
          >
            {this.state.items?.children?.map((groupData, index) => {
              return (
                <div
                  style={
                    this.state.dropPosition === groupData?.path
                      ? this.style?.highlight
                      : {}
                  }
                >
                  <Accordion
                    sx={{
                      border: "1.5px solid #e3e3e3",
                    }}
                  >
                    <DragNDropContainer
                      path={groupData?.path}
                      dragItem={{ element: groupData }}
                      onDrop={this.onDropEventHandler}
                      highlightDrop={this.highlightDrop}
                      canDrag={true}
                      dragType={"groups"}
                      dropType={["groups"]}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <DragIndicatorIcon style={{ cursor: "pointer" }} />
                        <Grid container alignItems="center">
                          <Grid item xs={10}>
                            <Typography variant="h7">
                              {groupData?.name}
                            </Typography>
                          </Grid>

                          <Grid
                            item
                            xs={2}
                            className="d-flex"
                            direction="row"
                            justifyContent="flex-end"
                          >
                            <Typography
                              style={{ cursor: "pointer" }}
                              onClick={(e) => {
                                e.stopPropagation();
                                let group = groupData || {};
                                group['editIndex'] = index;
                                let formErrors = this.state.formErrors || {};
                                formErrors['groupError'] = {};
                                let modal = this.state.modal || {};
                                modal['showGroupModal'] = true;
                                this.setState({
                                    group,
                                    editGroup: true,
                                    formErrors,
                                    modal,
                                });
                              }}
                            >
                              <IconButton>
                                <EditIcon />
                              </IconButton>
                            </Typography>
                            <Typography
                              style={{ cursor: "pointer" }}
                              onClick={(e) => {
                                e.stopPropagation();
                                this.handleAddEditDeleteGroup("delete", index);
                              }}
                            >
                              <IconButton color="error">
                                <DeleteOutlineIcon />
                              </IconButton>
                            </Typography>
                          </Grid>
                        </Grid>
                      </AccordionSummary>
                    </DragNDropContainer>
                    <Grid
                      container
                      spacing={2}
                      style={{
                        margin: "10px 0",
                        width: "99%",
                        alignItems: "center",
                      }}
                    >
                      <Grid item xs={12}>
                        <PrinterTable
                          printers={groupData?.printers}
                          updatePrinterData={this.updatePrinterData}
                          groupIndex={index}
                        />
                      </Grid>
                      <Grid item xs={8}>
                        <Typography variant="h6">
                          {i18n.t("categories")}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={4}
                        className="d-flex"
                        direction="row"
                        justifyContent="flex-end"
                      >
                        <Button
                          variant="outlined"
                          startIcon={<AddIcon />}
                          onClick={() => {
                            let categoryData =  {};
                            categoryData['groupIndex'] = index;
                            let formErrors = this.state.formErrors || {};
                            formErrors['categoryError'] = {};
                            let modal = this.state.modal || {};
                            modal['showCategoryModal'] = true;
                            this.setState({
                                categoryData,
                                modal,
                                editCategory: false,
                                formErrors,
                            });
                            this.props.showDiscardWarning(true);
                          }}
                        >
                          {i18n.t("add_category")}
                        </Button>
                      </Grid>
                    </Grid>
                    {groupData?.children?.length > 0 && (
                      <Divider style={this.style?.dividerStyles} />
                    )}
                    <DragNDropContainer
                      key="empty-div"
                      containerType="droppable"
                      onDrop={this.onDropEventHandler}
                      highlightDrop={this.highlightDrop}
                      isEmpty
                      canDrag={true}
                      dragType=""
                      dropType={["categories"]}
                      padding="5px 0"
                    >
                      <AccordionDetails>
                        {groupData?.children?.map(
                          (categories, categoryIndex) => {
                            return (
                              <div
                                style={
                                  this.state.dropPosition === categories?.path
                                    ? this.style?.highlight
                                    : {}
                                }
                              >
                                <Accordion
                                  sx={{
                                    border: "1.5px solid #e3e3e3",
                                  }}
                                >
                                  <DragNDropContainer
                                    dragItem={{ element: categories }}
                                    onDrop={this.onDropEventHandler}
                                    highlightDrop={this.highlightDrop}
                                    canDrag={true}
                                    path={categories?.path}
                                    dragType={"categories"}
                                    dropType={["categories"]}
                                  >
                                    <AccordionSummary
                                      expandIcon={<ExpandMoreIcon />}
                                      aria-controls="panel1a-content"
                                      id="panel1a-header"
                                    >
                                      <DragIndicatorIcon
                                        style={{ cursor: "pointer" }}
                                      />
                                      <Grid container alignItems="center">
                                        <Grid item xs={10}>
                                          <Typography>
                                            {categories?.name}
                                          </Typography>
                                        </Grid>
                                        <Grid
                                          item
                                          xs={2}
                                          className="d-flex"
                                          direction="row"
                                          justifyContent="flex-end"
                                        >
                                          <Typography
                                            style={{ cursor: "pointer" }}
                                            onClick={async(e) => {
                                              e.stopPropagation();
                                              let categoryData = deepClone(categories) || {};
                                              categoryData['editIndex'] = categoryIndex;
                                              categoryData['groupIndex'] = index;
                                              let formErrors = this.state.formErrors || {};
                                              formErrors['categoryError'] = {};
                                              let modal = this.state.modal || {};
                                              modal['showCategoryModal'] = true;
                                              await this.setState({
                                                categoryData,
                                                formErrors,
                                                editCategory:true,
                                                modal
                                              });
                                            }}
                                          >
                                            <IconButton>
                                              <EditIcon />
                                            </IconButton>
                                          </Typography>
                                          <Typography
                                            style={{ cursor: "pointer" }}
                                            onClick={(e) => {
                                              e.stopPropagation();
                                              this.handleAddEditDeleteCategory(
                                                "delete",
                                                index,
                                                categoryIndex
                                              );
                                            }}
                                          >
                                            <IconButton color="error">
                                              <DeleteOutlineIcon />
                                            </IconButton>
                                          </Typography>
                                        </Grid>
                                      </Grid>
                                    </AccordionSummary>
                                  </DragNDropContainer>
                                  <Divider style={this.styles?.dividerStyles} />
                                  <AccordionDetails>
                                    <ConsumptionsTable
                                      data={categories}
                                      onDragEnd={this.onDropEventHandler}
                                      groupIndex={index}
                                      dropPosition={this.state.dropPosition}
                                      categoryIndex={categoryIndex}
                                      onAction={this.handleConsumption}
                                    />
                                  </AccordionDetails>
                                </Accordion>
                              </div>
                            );
                          }
                        )}
                      </AccordionDetails>
                    </DragNDropContainer>
                  </Accordion>
                </div>
              );
            })}
          </DragNDropContainer>
          <Modal
            open={this.state.modal.showGroupModal}
            onClose={async() => {
              let modal = this.state.modal || {};
              modal['showGroupModal'] = true;
              await this.setState({
                modal
              });
            }}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <div>
              <Box sx={this.style?.modelClass}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  {i18n.t("group")}
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                  <Grid
                    container
                    rowSpacing={1}
                    columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                    style={{ alignItems: "center" }}
                  >
                    <Grid item xs={6}>
                      <TextField
                        required
                        fullWidth
                        type="text"
                        id="name"
                        label={i18n.t("name")}
                        name="name"
                        autoComplete="name"
                        onChange={async (e) => {
                          let group = this.state.group || {};
                          group['name'] = e.target.value;
                          await this.setState({
                            group
                          });
                          await this.validateFormField("name");
                        }}
                        error={
                          this.state.formErrors?.groupError?.name ? true : false
                        }
                        helperText={this.state.formErrors?.groupError?.name}
                        value={this.state?.group?.name}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <FormControl fullWidth>
                        <InputLabel id="order-status-select-label">
                          {i18n.t("order_status")} *
                        </InputLabel>
                        <Select
                          fullWidth
                          labelId="order-status-select-label"
                          id="order-status-select"
                          value={
                            this.state?.group?.defaultOrderStatus || ""
                          }
                          label={i18n.t("order_status")}
                          onChange={async(e) => {
                            let group = this.state.group || {};
                            group['defaultOrderStatus'] = e.target.value;
                            await this.setState({
                              group
                            });
                            await this.validateFormField("defaultOrderStatus");
                          }}
                          error={this.state.formErrors?.groupError?.defaultOrderStatus}
                        >
                          {(this.orderStatusOptions || []).map((el) => (
                            <MenuItem value={el.value}>{el.label}</MenuItem>
                          ))}
                        </Select>
                        <FormHelperText error={true}>
                          {this.state.formErrors?.groupError?.defaultOrderStatus}
                        </FormHelperText>
                      </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                      <LocalizationProvider dateAdapter={AdapterMoment}>
                        <CustomTimePicker
                          label={i18n.t("available_from")}
                          onChange={async(newValue) => {
                            let group = this.state.group || {};
                            group['availableFrom'] = newValue?._d;
                            await this.setState({
                              group
                            });
                          }}
                          onError={""}
                          ampm={false}
                          value={
                            this.state?.group?.availableFrom || null
                          }
                          sx={{ width: 220 }}
                          renderInput={(params) => <TextField {...params} />}
                        />
                      </LocalizationProvider>
                    </Grid>
                    <Grid item xs={6}>
                      <LocalizationProvider dateAdapter={AdapterMoment}>
                        <CustomTimePicker
                          label={i18n.t("available_to")}
                          onChange={async(newValue) => {
                              let group = this.state.group || {};
                              group['availableTo'] = newValue?._d;
                              await this.setState({
                                group
                              });
                          }}
                          ampm={false}
                          value={
                            this.state?.group?.availableTo || null
                          }
                          renderInput={(params) => <TextField {...params} />}
                          sx={{ width: 220 }}
                        />
                      </LocalizationProvider>
                    </Grid>
                    <Grid item>
                      <FormControlLabel
                        control={
                          <StatusSwitch
                            onChange={async (e) => {
                              let group = this.state.group || {};
                              group['online_hidden'] = e.target.checked;
                              await this.setState({
                                group
                              });
                            }}
                            checked={this.state?.group?.online_hidden}
                            className="mt-1"
                            style={{ cursor: "pointer" }}
                          />
                        }
                        label={i18n.t("online_hidden")}
                      />
                    </Grid>
                    <Grid item>
                      <FormControlLabel
                        control={
                          <StatusSwitch
                            onChange={async (e) => {
                              let group = this.state.group || {};
                              group['local_hidden'] = e.target.checked;
                              await this.setState({
                                group
                              });
                            }}
                            checked={this.state?.group?.local_hidden}
                            className="mt-1"
                            style={{ cursor: "pointer" }}
                          />
                        }
                        label={i18n.t("local_hidden")}
                      />
                    </Grid>
                    <Grid item>
                      <FormControlLabel
                        control={
                          <StatusSwitch
                            onChange={async (e) => {
                              let group = this.state.group || {};
                              group['defaultMarkAsCompleted'] = e.target.checked;
                              await this.setState({
                                group
                              });
                            }}
                            checked={this.state?.group?.defaultMarkAsCompleted}
                            className="mt-1"
                            style={{ cursor: "pointer" }}
                          />
                        }
                        label={i18n.t("default_mark_as_completed")}
                      />
                    </Grid>
                  </Grid>
                </Typography>
                <div style={{ justifyContent: "flex-end", display: "flex" }}>
                  <Button
                    variant="contained"
                    style={{ margin: "10px" }}
                    onClick={() => {
                      this.saveGroupData();
                    }}
                  >
                    {i18n.t("Ok")}
                  </Button>
                  <Button
                    variant="contained"
                    style={{ margin: "10px" }}
                    onClick={async() => {
                      let modal = this.state.modal || {};
                      modal['showGroupModal'] = false;
                      await this.setState({
                        modal
                      });
                    }}
                  >
                    {i18n.t("cancel")}
                  </Button>
                </div>
              </Box>
            </div>
          </Modal>
          <Modal
            open={this.state.modal.showCategoryModal}
            onClose={async() => {
              let modal = this.state.modal || {};
              modal['showCategoryModal'] = true;
              await this.setState({
                modal
              });
            }}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <div>
              <Box sx={this.style?.modelClass}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  {i18n.t("category")}
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                  <Grid
                    container
                    rowSpacing={1}
                    columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                    style={{ alignItems: "center" }}
                  >
                    <Grid item xs={12}>
                      <TextField
                        required
                        fullWidth
                        type="text"
                        id="name"
                        label={i18n.t("name")}
                        name="name"
                        autoComplete="name"
                        onChange={async (e) => {
                          let categoryData = this.state.categoryData || {};
                          categoryData['name'] = e.target.value;
                          await this.setState({
                            categoryData
                          });
                          await this.validateCategoryFormField("name");
                        }}
                        error={
                          this.state.formErrors?.categoryError?.name
                            ? true
                            : false
                        }
                        helperText={this.state.formErrors?.categoryError?.name}
                        value={this.state?.categoryData?.name}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl fullWidth>
                        <InputLabel id="color-select-label">{i18n.t('color')}</InputLabel>
                        <Select
                          labelId="color-select-label"
                          id="color-select"
                          value={this.state.categoryData?.color || ""}
                          label={i18n.t("color")}
                          onChange={async (e) => {
                            let categoryData = this.state.categoryData || {};
                            categoryData['color'] = e.target.value;
                            await this.setState({
                              categoryData
                            });
                          }}
                        >
                          {(config?.consumptionColors || []).map((el) => (
                            <MenuItem value={el.id} style={{color: el.colorCode}}>{el.name}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item>
                      <FormControlLabel
                        control={
                          <StatusSwitch
                            onChange={async (e) => {
                              let categoryData = this.state.categoryData || {};
                              categoryData['online_hidden'] = e.target.checked;
                              await this.setState({
                                categoryData
                              });
                            }}
                            checked={this.state?.categoryData?.online_hidden}
                            className="mt-1"
                            style={{ cursor: "pointer" }}
                          />
                        }
                        label={i18n.t("online_hidden")}
                      />
                    </Grid>
                    <Grid item>
                      <FormControlLabel
                        control={
                          <StatusSwitch
                            onChange={async (e) => {
                              let categoryData = this.state.categoryData || {};
                              categoryData['local_hidden'] = e.target.checked;
                              await this.setState({
                                categoryData
                              });
                            }}
                            checked={this.state?.categoryData?.local_hidden}
                            className="mt-1"
                            style={{ cursor: "pointer" }}
                          />
                        }
                        label={i18n.t("local_hidden")}
                      />
                    </Grid>
                  </Grid>
                </Typography>
                <div style={{ justifyContent: "flex-end", display: "flex" }}>
                  <Button
                    variant="contained"
                    style={{ margin: "10px" }}
                    onClick={() => {
                      this.saveCategoryData();
                    }}
                  >
                    {i18n.t("Ok")}
                  </Button>
                  <Button
                    variant="contained"
                    style={{ margin: "10px" }}
                    onClick={async() => {
                      let modal = this.state.modal || {};
                      modal['showCategoryModal'] = false;
                      await this.setState({
                            modal
                      });  
                    }}
                  >
                    {i18n.t("cancel")}
                  </Button>
                </div>
              </Box>
            </div>
          </Modal>
          <Modal
            open={this.state.modal.showConsumptionsModal}
            onClose={async() => {
              let modal = this.state.modal || {};
              modal['showConsumptionsModal'] = true;
              await this.setState({
                    modal
              });  
            }}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <div>
              <Box sx={this.style?.modelClass}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  {i18n.t("consumption")}
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <TextField
                        required
                        fullWidth
                        type="text"
                        id="name"
                        label={i18n.t("name")}
                        name="name"
                        autoComplete="name"
                        onChange={async (e) => {
                          let consumptionData = this.state.consumptionData || {};
                          consumptionData['name'] = e.target.value ;
                          await this.setState({
                                consumptionData
                          });  
                          await this.validateConsumptionFormField("name");
                        }}
                        error={
                          this.state.formErrors?.consumptionError?.name
                            ? true
                            : false
                        }
                        helperText={
                          this.state.formErrors?.consumptionError?.name
                        }
                        value={this.state?.consumptionData?.name}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        required
                        fullWidth
                        type="text"
                        id="price"
                        label={i18n.t("price")}
                        name="price"
                        autoComplete="price"
                        onChange={async (e) => {
                          let consumptionData = this.state.consumptionData || {};
                          consumptionData['price'] = e.target.value ;
                          await this.setState({
                              consumptionData,
                          });
                          await this.validateConsumptionFormField("price");
                        }}
                        error={
                          this.state.formErrors?.consumptionError?.price
                            ? true
                            : false
                        }
                        helperText={
                          this.state.formErrors?.consumptionError?.price
                        }
                        value={this.state?.consumptionData?.price}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl fullWidth>
                        <InputLabel id="color-select-label">{i18n.t('color')}</InputLabel>
                        <Select
                          labelId="color-select-label"
                          id="color-select"
                          value={this.state.consumptionData?.color || ""}
                          label={i18n.t("color")}
                          onChange={async (e) => {
                            let consumptionData = this.state.consumptionData || {};
                            consumptionData['color'] = e.target.value;
                            await this.setState({
                              consumptionData
                            });
                          }}
                        >
                          {(config?.consumptionColors || []).map((el) => (
                            <MenuItem value={el.id} style={{color: el.colorCode}}>{el.name}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        id="description"
                        label={i18n.t("description")}
                        name="description"
                        multiline
                        rows={2}
                        maxRows={4}
                        autoComplete="description"
                        onChange={async (e) => {
                          let consumptionData = this.state.consumptionData || {};
                          consumptionData['description'] = e.target.value ;
                          await this.setState({
                                consumptionData
                          });  
                          await this.validateConsumptionFormField(
                            "description"
                          );
                        }}
                        error={
                          this.state.formErrors?.consumptionError?.description
                            ? true
                            : false
                        }
                        helperText={
                          this.state.formErrors?.consumptionError?.description
                        }
                        value={
                          this.state?.consumptionData?.description
                        }
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Autocomplete
                        sx={{ marginTop: 1, marginBottom: 1 }}
                        single
                        id="vat_rates"
                        options={this?.props?.VATOptions || []}
                        autoHighlight={true}
                        autoSelect={true}
                        value={this.state?.consumptionData?.VAT}
                        isOptionEqualToValue={(option, value) => option.value === value.value}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={i18n.t("vat_rates")}
                            error={
                              this.state.formErrors?.consumptionError?.vat_rates
                                ? true
                                : false
                            }
                            helperText={
                              this.state.formErrors?.consumptionError?.vat_rates
                            }
                          />
                        )}
                        onChange={async (option, value) => {
                          let consumptionData = this.state.consumptionData || {};
                          consumptionData['VAT'] = value?.value ;
                          await this.setState({
                                consumptionData
                          });  
                          await this.validateConsumptionFormField("vat_rates");
                        }}
                        filterOptions={(options, params) => {
                          const { inputValue } = params;
                          // check if input value is a number
                          const isNumber = !isNaN(inputValue);
                          if (!isNumber) {
                            return options;
                          }

                          const filtered = options.filter((option) => {
                            return option.label.includes(inputValue);
                          });
                          const isExisting = options.some(
                            (option) => inputValue === option.label
                          );
                          if (inputValue !== "" && !isExisting) {
                            filtered.push({
                              value: Number(inputValue),
                              label: `${inputValue}`,
                            });
                          }
                          return filtered;
                        }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Autocomplete
                        sx={{ marginTop: 1, marginBottom: 1 }}
                        single
                        id="type"
                        options={config?.consumptionRoundingTypes}
                        value={this.state?.consumptionData?.type?.label || ''}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            required
                            label={i18n.t("type")}
                            error={
                              this.state.formErrors?.consumptionError?.type
                                ? true
                                : false
                            }
                            helperText={
                              this.state.formErrors?.consumptionError?.type
                            }
                          />
                        )}
                        onChange={async (option, value) => {
                          let consumptionData = this.state.consumptionData || {};
                          consumptionData['type'] = {label:value?.label,value:value?.value} ;
                          consumptionData['round'] = null ;
                          await this.setState({
                                consumptionData
                          });  
                          await this.validateConsumptionFormField("type");
                        }}
                      />
                    </Grid>
                    {this.state?.consumptionData?.type?.value === 'input' && <Grid item xs={6}>
                      <Autocomplete
                        sx={{ marginTop: 1, marginBottom: 1 }}
                        single
                        id="round"
                        options={config?.consumptionRoundingOptions}
                        value={this.state?.consumptionData?.round || ''}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={i18n.t("round")}
                            error={
                              this.state.formErrors?.consumptionError?.round
                                ? true
                                : false
                            }
                            helperText={
                              this.state.formErrors?.consumptionError?.round
                            }
                          />
                        )}
                        onChange={async (option, value) => {
                          let consumptionData = this.state.consumptionData || {};
                          consumptionData['round'] = value?.value ;
                          await this.setState({
                                consumptionData
                          });  
                          await this.validateConsumptionFormField("round");
                        }}
                      />
                    </Grid>}
                    <Grid container spacing={2} sx={{ mt: 0, ml: 2 }}>
                      <Grid item>
                        <FormControlLabel
                          control={
                            <StatusSwitch
                              onChange={async (e) => {
                                let consumptionData = this.state.consumptionData || {};
                                consumptionData['quickOrder'] = e.target.checked;
                                await this.setState({
                                      consumptionData
                                });    
                              }}
                              checked={
                                this.state?.consumptionData?.quickOrder
                              }
                              style={{ cursor: "pointer" }}
                            />
                          }
                          label={i18n.t("quick_order")}
                        />
                      </Grid>
                      <Grid item>
                        <FormControlLabel
                          control={
                            <StatusSwitch
                              onChange={async (e) => {
                                let consumptionData = this.state.consumptionData || {};
                                consumptionData['online_hidden'] = e.target.checked;
                                await this.setState({
                                      consumptionData
                                });    
                              }}
                              checked={this.state?.consumptionData?.online_hidden}
                              style={{ cursor: "pointer" }}
                            />
                          }
                          label={i18n.t("online_hidden")}
                        />
                      </Grid>
                      <Grid item>
                        <FormControlLabel
                          control={
                            <StatusSwitch
                              onChange={async (e) => {
                                let consumptionData = this.state.consumptionData || {};
                                consumptionData['local_hidden'] = e.target.checked;
                                await this.setState({
                                      consumptionData
                                });    
                              }}
                              checked={this.state?.consumptionData?.local_hidden}
                              style={{ cursor: "pointer" }}
                            />
                          }
                          label={i18n.t("local_hidden")}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    spacing={2}
                    className="d-flex"
                    direction="row"
                    justifyContent="flex-end"
                  >
                    <Grid item>
                      <Button
                        variant="contained"
                        style={{ margin: "10px" }}
                        onClick={() => {
                          this.saveConsumptionData();
                        }}
                      >
                        {i18n.t("Ok")}
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        variant="contained"
                        style={{ margin: "10px" }}
                        onClick={async() => {
                          let modal = this.state.modal || {};
                          modal['showConsumptionsModal'] = false;
                          await this.setState({
                                modal
                          });    
                        }}
                      >
                        {i18n.t("cancel")}
                      </Button>
                    </Grid>
                  </Grid>
                </Typography>
              </Box>
            </div>
          </Modal>
        </DndProvider>
        <div>
          <Dialog
            open={
              this.state?.deletePopUp?.group ||
              this.state?.deletePopUp?.category ||
              this.state?.deletePopUp?.consumption
            }
            onClose={() => {
              this.setState({
                deletePopUp: {
                  group: false,
                  category: false,
                  consumption: false,
                },
              });
            }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{"Delete"}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Are you sure of deleting , this action is irreversible.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => this.handleConfirmDialog()}>
                Proceed
              </Button>
              <Button
                onClick={() => {
                  this.setState({
                    deletePopUp: {
                      group: false,
                      category: false,
                      consumption: false,
                    },
                  });
                }}
                autoFocus
              >
                Cancel
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </>
    );
  }
}
export default AccordionGroup;
